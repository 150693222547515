<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="agreement_1"
          @click="emitter()"
        >
          <div slot='label'>Oboznámil som sa s <a
              href="https://docs.google.com/gview?embedded=true&url=https://api.nasehnutie.sk/uploads/0398bb14-8439-47b3-8b04-8b0f5ba6821f.pdf"
              target="_blank"
              @click.stop
            >podmienkami spracúvania osobných údajov.</a></div>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="agreement_2"
          @click="emitter()"
        >
          <div slot='label'>Súhlasím s tým, že prevádzkovateľ bude spracúvať moje osobné údaje vo vyššie vyplnenom rozsahu v súlade s <a
              href="https://docs.google.com/gview?embedded=true&url=https://api.nasehnutie.sk/uploads/0398bb14-8439-47b3-8b04-8b0f5ba6821f.pdf"
              target="_blank"
              @click.stop
            >podmienkami spracúvania osobných údajov</a> na účel realizovania predmetu činnosti občianskeho združenia v súlade so stanovami občianskeho združenia, zabezpečovanie vnútornej organizácie a fungovania občianskeho združenia, ako aj na účel propagácie občianskeho združenia, jeho aktivít a predstaviteľov. Súhlas udeľujem na celú dobu trvania mojej registrácie. Som si vedomý, že súhlas môžem kedykoľvek odvolať. Beriem na vedomie, že odvolaním tohto súhlasu nebude dotknutá zákonnosť spracúvania osobných údajov pred odvolaním súhlasu.
          </div>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "GDPRConsent",
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    agreement_1: false,
    agreement_2: false,
  }),

  mounted: function () {
  },

  watch: {

  },

  methods: {
    emitter() {
      this.$emit("input", this.agreement_1 && this.agreement_2);
    }
  }
};
</script>>